import React, { useState, useEffect } from "react";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
const RoundInstructions = () => {
  const language = useSelector((state) => state.language);
  //const DemoContestData = DemoContestQuery(language);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div className="next-round">
      <h4 className="join-round"> How to Join<br></br> Next Round</h4>
      <ol class="step-list -champion">
        <li class="step-list__item">
          <a href="https://client.roxcapitals.com/login" target="_blank">Log in</a> or <a href="https://client.roxcapitals.com/register/forex/live" target="_blank">Sign up</a> on Rox Capitals
        </li>
        <li class="step-list__item">
          Open a new Demo contest word cup account
        </li>
        <li class="step-list__item">
          <a href="https://www.metatrader5.com/en/download" target="_blank">Download the MT5 trading platform</a>
        </li>
        <li class="step-list__item">
          Start trading on MT5 using the contest account
        </li>
        <li class="step-list__item">Get the highest balance and win a prize!</li>
      </ol>
      <p><b>Note : </b>
        After Registration, all contest participants will eligible to trade their contest accounts from next month's day 1st to 30th day.</p>
      <div class="win-pagination">
        <a href="https://client.roxcapitals.com/register/forex/live" class="btn-page">Participate Now</a>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(RoundInstructions);