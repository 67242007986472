import React from "react";
import { DemoTermsQuery } from "../../../prismic/staticQueries/DemoContestAllQuery/index.js";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";
import { PrismicRichText } from "@prismicio/react";
import CountUp from "react-countup";

const DemoTerms = () => {
  const language = useSelector((state) => state.language);
  const DemoTermsData = DemoTermsQuery(language);
  console.log("language", DemoTermsData);
  const { terms_box } = DemoTermsData;

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <section className="p-bottom-80 faqs">
        <Container className="">
          <Row>
            <Col lg={12}>
              <ul>
                {terms_box.map((reason, reasonInd) => (
                  <li className="text-gray my-3" key={"reasonInd" + reasonInd}> {reason.DemoTerms_content.text}</li>
                ))}

              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(DemoTerms);