import React from "react";
import { connect, useSelector } from "react-redux";
import demo_side_image from "../../../images/siteImages/home/prize-3.png";
import { DemoContestQuery } from "../../../prismic/staticQueries/DemoContestAllQuery/index.js"

//import amazon from "../../../images/siteImages/StocksIcons/Amazon_icon.png";

import { Link } from "gatsby";
const DemoBanner = ({ heading = {}, buttons = [], demo_banner_image = {} }) => {
  const language = useSelector((state) => state.language);
  const DemoContestData = DemoContestQuery(language);
  const {
    demo_heading,
    demo_content,
   // demo_side_image,
    demo_link,
    demo_link_text
  } = DemoContestData;
  return (
    <>
      <section className="topBanner breadCrumbBanner" style={{ height: "" }}>
        <div
          className="bg_demo_image d-flex justify-content-center align-items-center"
          style={{ backgroundImage: `url(${demo_banner_image})`, opacity: 1 }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="breadcrumb_wrapper d-flex flex-column align-items-center">
                  <h2 className="page_title" style={{color:"#00549c"}}>{demo_heading.text}</h2>
                  <p>{demo_content.text}</p>
                  <Link to={demo_link.text} className="demolink">
                    {demo_link_text.text}
                  </Link>
                </div>
              </div>
              <div className="col-md-2"></div>
              <div className="col-md-5">
                <img alt="ddd" src={demo_side_image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="orbit">
      <ul>
        <li>
          <img src={amazon} className="floating animate__animated animate__zoomInDown"></img>
        </li>
      </ul>
    </div> */}
    </>
  );
};

export default DemoBanner;
