import React, { useState, useEffect } from "react";
import $ from "jquery";
// import { DemoContestQuery } from "../../prismic/staticQueries/DemoContestAllQuery/index.js"
import Layout from "../../layout";
// import { Subscription, BreadCrumbBanner } from "../../components/common";
import "react-multi-carousel/lib/styles.css";
import { connect, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import banner_image from "../../images/siteImages/home/demo-banner-bg.png";
import DemoContest from "./DemoContest";
import WinnerDemo from "./Components/WinnerDemo";
import RoundInstructions from "./Components/RoundInstructions";
import ContestRegistration from "./Components/ContestRegistration";
import DemoBanner from "./Components/DemoBanner";
import DemoTerms from "./Components/DemoTerms";
import PaymentSlider from "../Company/PaymentSlider";

const HallOfFame = () => {
  const language = useSelector((state) => state.language);
  //const DemoContestData = DemoContestQuery(language);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const [leaderboardData, setLeaderboardData] = useState([]);
  const [monthYearData, setMonthYearData] = useState([]);
  const [selectedRound, setSelectedRound] = useState(null); // Track the selected round

  useEffect(() => {
    generateMonthYearData();
    getdata(4, 24); // Fetch initial data on component mount

    const listItems = document.querySelectorAll('#experienceTab li');
    listItems.forEach(item => {
      item.addEventListener('click', function () {
        listItems.forEach(item => {
          item.classList.remove('active');
        });
        this.classList.add('active');
        const month = parseInt(this.getAttribute('data-month'));
        const year = parseInt(this.getAttribute('data-year'));
        getdata(month, year);
        setSelectedRound(`${month}-${year}`);
      });
    });
  }, []);

  const generateMonthYearData = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const newMonthYearData = [];

    // // Start from October 2022 and end at the current month and year
    // for (let year = 2022; year <= currentYear; year++) {
    //   const startMonth = (year === 2022) ? 10 : 1; // Start from October for the year 2022, January otherwise
    //   const endMonth = (year === currentYear) ? currentMonth : 12;

    //   for (let month = startMonth; month <= endMonth; month++) {
    //     const registrationStartDate = new Date(year, month - 1, 1); // Subtract 1 from month to adjust for zero-indexing
    //     const registrationEndDate = new Date(year, month - 1, new Date(year, month, 0).getDate());
    //     const durationStartDate = new Date(year, month - 1, 1);
    //     const durationEndDate = new Date(year, month - 1, new Date(year, month, 0).getDate());

    //     newMonthYearData.push({
    //       month,
    //       year,
    //       registrationStartDate,
    //       registrationEndDate,
    //       durationStartDate,
    //       durationEndDate,
    //     });
    //   }
    // }

       // Start from April 2024 and end at the current month and year
       for (let year = 2024; year <= currentYear; year++) {
        const startMonth = (year === 2024) ? 4 : 1; // Start from April for the year 2024, January otherwise
        const endMonth = (year === currentYear) ? currentMonth : 12;
  
        for (let month = startMonth; month <= endMonth; month++) {
          const registrationStartDate = new Date(year, month - 1, 1); // Subtract 1 from month to adjust for zero-indexing
          const registrationEndDate = new Date(year, month - 1, new Date(year, month, 0).getDate());
          const durationStartDate = new Date(year, month - 1, 1);
          const durationEndDate = new Date(year, month - 1, new Date(year, month, 0).getDate());
  
          newMonthYearData.push({
            month,
            year,
            registrationStartDate,
            registrationEndDate,
            durationStartDate,
            durationEndDate,
          });
        }
      }

    setMonthYearData(newMonthYearData.reverse());
  };

  function getdata(month, year) {
    let findall_contentDiv = document.querySelector('.leaderboard__profiles_mon');
    console.log("MONTH",month);
    console.log("YEAR",year);
    const date = new Date()
    console.log("MONTHS",date.getMonth());
    if(date.getMonth()+1 == month){
      fetch(`https://metatrader.roxcapitals.com/lead.php`)
      .then(response => response.json())
      .then(data => {
        setLeaderboardData(data.splice(0,10));
        console.log("Get Data Showing", data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
    else{
      fetch(`https://metatrader.roxcapitals.com/get_top_users.php?month=${month}&year=${year}`)
      .then(response => response.json())
      .then(data => {
        setLeaderboardData(data);
        console.log("Get Data Showing", data)
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    }
    
  }

  const [activeTab, setActiveTab] = useState('Section1');

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };
  const getMonthName = (monthNumber) => {
    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];
    return months[monthNumber - 1];
  };

  return (

    <Layout>
    <DemoBanner demo_banner_image={banner_image}  />
      {/* <BreadCrumbBanner banner_image={banner_image} /> */}
      <section className="sectionbg py-5">
        <Container className="">
          <div className="tab" role="tabpanel">
            <ul className="nav nav-tabs" role="tablist">
              <li role="presentation" className={activeTab === 'Section1' ? 'active' : ''}>
                <a
                  aria-controls="home"
                  role="tab"
                  onClick={() => handleTabClick('Section1')}
                >
                  Current Round Leaders
                </a>
              </li>
              <li role="presentation" className={activeTab === 'Section2' ? 'active' : ''}>
                <a
                  aria-controls="profile"
                  role="tab"
                  onClick={() => handleTabClick('Section2')}
                >
                  Hall of Fame
                </a>
              </li>
              <li role="presentation" className={activeTab === 'Section3' ? 'active' : ''}>
                <a
                  aria-controls="messages"
                  role="tab"
                  onClick={() => handleTabClick('Section3')}
                >
                  Terms and Conditions
                </a>
              </li>
            </ul>
            <div className="tab-content tabs">
              <div
                role="tabpanel"
                className={`tab-pane ${activeTab === 'Section1' ? 'in active' : ''}`}
                id="Section1"
              >
                <h3>Current Round Leaders</h3>
                <p>
                  <Row>
                    <Col md="12">
                      <WinnerDemo></WinnerDemo>
                    </Col>
                    <Col xl="5" lg="6">
                      <RoundInstructions></RoundInstructions>
                    </Col>
                    <Col xl="7" lg="6">
                      <DemoContest></DemoContest>
                    </Col>
                  </Row>
                </p>
              </div>
              <div
                role="tabpanel"
                className={`tab-pane ${activeTab === 'Section2' ? 'in active' : ''}`}
                id="Section2"
              >
                <h3>Hall of Fame</h3>
                <p>
                  <Row>
                    <Col md="12">
                      <WinnerDemo></WinnerDemo>
                    </Col>
                    <Col md="12">
                      <div className="v-tabs">
                        <div className="side-tab">
                          <ul className="nav nav-pills flex-xl-column" id="experienceTab" role="tablist">
                            {monthYearData.map(({ month, year }, index) => (
                              <li
                                key={index}
                                className={`nav-item ${selectedRound === `${month}-${year}` ? 'active' : ''}`}
                                onClick={() => {
                                  getdata(month, year);
                                  setSelectedRound(`${month}-${year}`);
                                }}
                                id={`test-${index}`}
                              >
                                <a className="nav-link">{getMonthName(month)} {year}</a>
                              </li>
                            ))}
                          </ul>
                        </div>
                        {/* <!-- /.col-md-4 --> */}
                        <div className="side-container">
                          {/* <div>
            <h5>Registration In</h5>
            {monthYearData.map(({ month, year, registrationStartDate, registrationEndDate }, index) => (
              <div key={index} className={selectedRound === `${month}-${year}` ? 'active' : 'inactive'}>
                <h5>
                  {getMonthName(month)} {year}
                  <br />
                  {registrationStartDate.toLocaleDateString()} to {registrationEndDate.toLocaleDateString()}
                </h5>
              </div>
            ))}
          </div> */}
                          <div className="tab-content" id="experienceTabContent">
                            <div className="tab-pane text-left text-light d-flex" id="oct-1" role="tabpanel" aria-labelledby="oct">
                              {/* <!-- <div className="duration tab-left" style="text-align: center;">
        </div> --> */}
                              <div className="tab-right">
                                <div className="leaderboard__profiles_head">
                                  <article className="leaderboard__profile_head headings">
                                    <span className="leaderboard_head">Rank</span>
                                    <span className="leaderboard_head">Name</span>
                                    <span className="leaderboard_head mob-hide">Profit</span>
                                    <span className="leaderboard_head mob-hide">Balance</span>
                                  </article>
                                </div>
                                <div className="leaderboard__profiles_mon">
                                  {leaderboardData.map((item, index) => {
                                    let medalImage;
                                    if (index === 0) {
                                      medalImage = 'gold-rank.png';
                                    } else if (index === 1) {
                                      medalImage = 'silver-rank.png';
                                    } else if (index === 2) {
                                      medalImage = 'bronze-rank.png';
                                    }
                                    return (
                                      <article key={index} className="leaderboard__profile">
                                        <span className="leaderboard__rank">
                                          {item.new_rank}
                                          {item.new_rank === item.old_rank ? (
                                            <img src="https://roxcapitals.com/wp-content/uploads/2022/08/neutral-svg.svg" width="15px" alt="Neutral" />
                                          ) : item.new_rank > item.old_rank ? (
                                            <img src="https://roxcapitals.com/wp-content/uploads/2022/08/up-arrow.svg" width="15px" alt="Up" />
                                          ) : (
                                            <img src="https://roxcapitals.com/wp-content/uploads/2022/08/down-arrow.svg" width="15px" alt="Down" />
                                          )}
                                        </span>
                                        <span className="leaderboard__name">{item.name}<img src={item.flag} style={{ width: "30px" }}></img></span>
                                        <span className="leaderboard__profit mob-hide">{item.profit}$</span>
                                        <span className="leaderboard__balance mob-hide">
                                          {item.balance}$
                                          {index < 3 && <img src={`https://roxcapitals.com/wp-content/uploads/2022/08/${medalImage}`} style={{ width: "30px", marginLeft: "15px" }} alt={`Position ${index + 1}`} />}
                                        </span>
                                      </article>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!--tab content end--> */}
                        </div>
                        {/* <!-- col-md-8 end --> */}
                      </div>
                    </Col>
                  </Row>
                </p>
              </div>
              <div
                role="tabpanel"
                className={`tab-pane ${activeTab === 'Section3' ? 'in active' : ''}`}
                id="Section3"
              >
                <h3>Terms and Conditions</h3>
                <p>
                  <DemoTerms></DemoTerms>
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
      
    </Layout>
  );
};
const mapStateToProps = (state) => {
  return {
    language: state.language,
  };
};

export default connect(mapStateToProps)(HallOfFame);